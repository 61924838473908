<!--
* @Author: DY
* @Date: 2021年1月8日14:49:09
* @Description: '价格设置表单-宁夏'
-->
<template>
    <div class="priceSettingForm priceSettingForm_kj" v-loading="loading">
        <div class="basicInfo">
            <table>
                <thead>
                    <tr>
                        <th colspan="5">
                            基础信息
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>合同编号</th>
                        <td class="tdStyle2" colspan="2">
                            {{ formData.contract_number }}
                        </td>
                        <th>工程名称</th>
                        <td class="tdStyle2">
                            {{ formData.engineering_name }}
                        </td>
                    </tr>
                    <tr>
                        <th>客户名称</th>
                        <td class="tdStyle2" colspan="2">
                            {{ formData.client_name }}
                        </td>
                        <th>价格模式</th>
                        <td>
                            <el-radio-group
                                v-model="formData.price_mode"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-radio :label="0">
                                    企业固定价
                                </el-radio>
                                <el-radio :label="1">
                                    市场信息价
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>对账信息</th>
                        <td colspan="2">
                            <el-radio-group
                                v-model="formData.check_cycle_type"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-radio :label="0">
                                    自然月
                                </el-radio>
                                <el-radio :label="1">
                                    每月
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.check_cycle_day"
                                        :disabled="formData.check_cycle_type == 0 || vueFormParams.IsRead"
                                    ></el-input>
                                    日
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <th>税率</th>
                        <td>
                            <el-radio-group
                                v-model="formData.tax_rate"
                                @change="modifyTaxRate"
                                :disabled="vueFormParams.IsRead"
                            >
                                <template v-for="(item, index) in taxRateData">
                                    <el-radio :key="index" :label="item.val">
                                        {{ item.name }}
                                    </el-radio>
                                </template>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p style="margin-bottom:0.1rem;">
                                价格取整
                            </p>
                        </th>
                        <td>
                            <el-radio-group
                                v-model="formData.price_value_type"
                                :disabled="vueFormParams.IsRead"
                                @change="switchCalculationMode"
                            >
                                <el-radio :label="0">
                                    取整
                                </el-radio>
                                <el-radio :label="1">
                                    小数点后1位
                                </el-radio>
                                <el-radio :label="2">
                                    小数点后2位
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <td>
                            <el-radio-group
                                v-model="formData.price_rounding_mode"
                                :disabled="vueFormParams.IsRead"
                                @change="switchCalculationMode"
                            >
                                <el-radio :label="0">
                                    进一法
                                </el-radio>
                                <el-radio :label="1">
                                    四舍五入
                                </el-radio>
                                <el-radio :label="2">
                                    舍掉
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <th>润泵砂浆价格</th>
                        <td>
                            <el-radio-group v-model="formData.mortar_price_type" :disabled="vueFormParams.IsRead">
                                <el-radio :label="0">
                                    与产品同价
                                </el-radio>
                                <el-radio :label="1">
                                    与产品不同价
                                    <p class="pStyle2">
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.mortar_price_percent"
                                            :disabled="!formData.mortar_price_type||vueFormParams.IsRead"
                                        ></el-input>
                                        %产品价
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.mortar_price"
                                            :disabled="!formData.mortar_price_type||vueFormParams.IsRead"
                                        ></el-input>
                                        元/方
                                    </p>
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>承兑方式</th>
                        <td colspan="4">
                            <el-checkbox v-model="formData.is_bank_acceptance" class="checkboxStyle1" :disabled="vueFormParams.IsRead">
                                <h4>银行承兑</h4>
                                <p class="pStyle2">
                                    6个月内加价
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.bank_acceptance_six_add_money"
                                        :disabled="!formData.is_bank_acceptance||vueFormParams.IsRead"
                                    ></el-input>
                                    元，
                                    超6个月加价
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.bank_acceptance_exceed_six_add_money"
                                        :disabled="!formData.is_bank_acceptance||vueFormParams.IsRead"
                                    ></el-input>
                                    元。
                                </p>
                            </el-checkbox>
                            <el-checkbox v-model="formData.is_trade_acceptance" class="checkboxStyle1" :disabled="vueFormParams.IsRead">
                                商业承兑
                                <p class="pStyle2">
                                    6个月内加价
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.trade_acceptance_six_add_money"
                                        :disabled="!formData.is_trade_acceptance||vueFormParams.IsRead"
                                    ></el-input>
                                    元，
                                    超6个月加价
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.trade_acceptance_exceed_six_add_money"
                                        :disabled="!formData.is_trade_acceptance||vueFormParams.IsRead"
                                    ></el-input>
                                    元。
                                </p>
                            </el-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <template v-if="formData.price_mode != 0">
            <div class="informationPriceSetting">
                <table>
                    <thead>
                        <tr>
                            <th colspan="5">
                                信息价设置
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>地区信息价</th>
                            <td colspan="2">
                                <el-radio-group v-model="formData.info_price_regional_number" @change="selectArea" :disabled="vueFormParams.IsRead">
                                    <template v-for="(item, index) in regionData">
                                        <el-radio :key="index" :label="item.info_price_regional_number">
                                            {{ item.region_name }}
                                        </el-radio>
                                    </template>
                                </el-radio-group>
                            </td>
                            <th>是否跨区组合</th>
                            <td>
                                <el-radio-group
                                    v-model="formData.is_trans_regional"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <th>信息价取值</th>
                            <td colspan="2">
                                <el-radio-group
                                    v-model="formData.get_price_type"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <el-radio :label="0">
                                        上期信息价
                                    </el-radio>
                                    <el-radio :label="1">
                                        当期信息价
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <th>合同下浮比例</th>
                            <td>
                                <p class="pStyle1">
                                    <input
                                        type="text"
                                        v-model="formData.discount_rate"
                                        class="inputStyle2"
                                        :disabled="vueFormParams.IsRead"
                                    >%
                                </p>
                                <br>
                                <el-radio-group v-model="formData.discount_rate_type" v-show="false">
                                    <el-radio :label="0">
                                        按含税单价
                                    </el-radio>
                                    <el-radio :label="1">
                                        按除税单价
                                    </el-radio>
                                </el-radio-group>
                                <el-radio-group
                                    v-model="formData.pump_discounts_mode"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <el-radio :label="1" @click.native.prevent="onRadioChange($event, 1)">
                                        仅泵送优惠， 非泵送 -
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.no_pump_minus_money"
                                            :disabled="formData.pump_discounts_mode != 1 || vueFormParams.IsRead"
                                            type="number"
                                            @mousewheel.native.prevent
                                            @change="preferentialValue"
                                        ></el-input>
                                        元
                                    </el-radio>
                                    <el-radio :label="2" @click.native.prevent="onRadioChange($event, 2)">
                                        仅非泵送优惠，泵送 +
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.pump_add_money"
                                            :disabled="formData.pump_discounts_mode != 2 || vueFormParams.IsRead"
                                            type="number"
                                            @mousewheel.native.prevent
                                            @change="preferentialValue"
                                        ></el-input>
                                        元
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <th>按基准期次价<br>格浮动调整</th>
                            <td colspan="4">
                                <el-radio-group v-model="formData.is_base_price_adjusted" :disabled="vueFormParams.IsRead">
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                                <p class="pStyle1" v-if="formData.is_base_price_adjusted">
                                    本期信息价浮动超出±
                                    <input
                                        type="text"
                                        v-model="formData.price_adjustment_range"
                                        class="inputStyle2"
                                        :disabled="vueFormParams.IsRead"
                                    >
                                    %时调整合同价格
                                </p>
                                <el-radio-group
                                    v-model="formData.adjustment_ratio_type"
                                    v-if="formData.is_base_price_adjusted"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <el-radio :label="0">
                                        按超出比例
                                    </el-radio>
                                </el-radio-group>
                                <el-radio-group
                                    v-model="formData.adjustment_calculation_type"
                                    v-if="formData.is_base_price_adjusted"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <el-radio :label="0">
                                        先浮动、后优惠
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr v-if="formData.is_base_price_adjusted">
                            <th>
                                基准信息价期次
                            </th>
                            <td colspan="2">
                                <el-select
                                    v-model="formData.base_price_period_number"
                                    placeholder="请选择"
                                    @change="selectPeriod"
                                    @focus.stop="selectPeriodFocus"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <template v-for="(item,index) in periodData">
                                        <el-option
                                            :key="index"
                                            :label="item.current_period"
                                            :value="item.info_price_period_number"
                                        >
                                        </el-option>
                                    </template>
                                </el-select>
                            </td>
                            <th>基准产品型号</th>
                            <td>
                                <el-select
                                    v-model="formData.base_info_price_number"
                                    placeholder="请选择"
                                    @change="selectProductModel"
                                    @focus.stop="selectProductModelFocus"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <template v-for="(item,index) in productModelData">
                                        <el-option
                                            :key="index"
                                            :label="item.specification_mode_name"
                                            :value="item.info_price_number"
                                        >
                                        </el-option>
                                    </template>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <th>浮动价格计算取值</th>
                            <td colspan="5">
                                <el-radio-group v-model="formData.floating_price_calculated" style="display: flex;">
                                    <el-radio :label="0">
                                        仅“产品标号”
                                    </el-radio>
                                    <el-radio :label="1">
                                        产品标号 + 附加型号
                                        <p class="pStyle4">
                                            附加型号优惠比例
                                            <input
                                                type="text"
                                                v-model="formData.additional_model_discount_rate"
                                                class="inputStyle2"
                                                :disabled="formData.floating_price_calculated == 0"
                                            >%
                                        </p>
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <template v-if="!vueFormParams.IsRead">
                <div class="marketInformationPrice">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="11">
                                    产品（市场信息价）
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>产品名称</th>
                                <th>规格型号</th>
                                <th>市场价格</th>
                                <th>优惠比例(%)</th>
                                <th>优惠金额(元)</th>
                                <th>含税单价(元)</th>
                                <th>税率(%)</th>
                                <th>除税单价(元)</th>
                                <th>附加型号</th>
                                <th>额外可用<br>附加型号</th>
                                <th>操作</th>
                            </tr>
                            <template v-for="(item,index) in formData.ContractPriceMarkNumList">
                                <tr :key="index" v-if="item.price_type == 1">
                                    <td>{{ item.info_price_name }}</td>
                                    <td>{{ item.specification_mode_name }}</td>
                                    <td>{{ item.market_price }}</td>
                                    <td>
                                        <el-input
                                            type="number"
                                            v-model="item.discount_rate"
                                            @input="infoPriceCalculation(item)"
                                            @mousewheel.native.prevent
                                            :disabled="vueFormParams.IsRead"
                                        ></el-input>
                                    </td>
                                    <td>
                                        <el-input
                                            type="number"
                                            v-model="item.discount_money"
                                            @input="infoPriceCalculation(item)"
                                            @mousewheel.native.prevent
                                            :disabled="vueFormParams.IsRead"
                                        ></el-input>
                                    </td>
                                    <td>{{ item.included_tax_unit_price }}</td>
                                    <td>{{ item.tax_rate }}</td>
                                    <td>{{ item.after_tax_unit_price }}</td>
                                    <td
                                        @click="selectModels(item)"
                                        style="color: #409EFF; cursor: pointer;"
                                    >
                                        ······
                                    </td>
                                    <td>
                                        <template v-if="item.additionalModels">
                                            <span
                                                class="tag-more-text"
                                                @click="viewMoreTag(item)"
                                            >
                                                ······
                                            </span>
                                        </template>
                                    </td>
                                    <td>
                                        <el-button
                                            type="danger"
                                            plain
                                            size="small"
                                            @click="delectPrice(item)"
                                            v-if="!vueFormParams.IsRead"
                                        >
                                            删除
                                        </el-button>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <button class="addBtn el-icon-plus" @click="selectMarketInformationPrice" v-if="!vueFormParams.IsRead">
                        点击此处新增
                    </button>
                </div>
            </template>
        </template>
        <template v-if="!vueFormParams.IsRead">
            <div class="marketInformationPrice">
                <table>
                    <thead>
                        <tr>
                            <th colspan="9">
                                产品（企业固定价）
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>产品名称</th>
                            <th>产品标号</th>
                            <th>含税单价(元)</th>
                            <th>税率(%)</th>
                            <th>除税单价(元)</th>
                            <th>优惠后价格(元)</th>
                            <th>额外可用<br>附加型号</th>
                            <th>是否优惠</th>
                            <th>操作</th>
                        </tr>
                        <template v-for="(item,index) in formData.ContractPriceMarkNumList">
                            <tr
                                v-if="item.price_type == 0"
                                :key="`marketInformationPrice_${index}`"
                                :class="{'floorPrice': item.product_mark_price_min > item.included_tax_unit_price}"
                            >
                                <td>{{ item.info_price_name }}</td>
                                <td>{{ item.product_mark_num }}</td>
                                <td>
                                    <el-input
                                        type="number"
                                        v-model="item.included_tax_unit_price"
                                        @input="enterprisePriceCalculation(item)"
                                        @mousewheel.native.prevent
                                        :disabled="vueFormParams.IsRead"
                                    ></el-input>
                                </td>
                                <td>{{ item.tax_rate }}</td>
                                <td>{{ item.after_tax_unit_price }}</td>
                                <td>{{ item.product_mark_price }}</td>
                                <td>
                                    <template v-if="item.additionalModels">
                                        <span class="tag-more-text" @click="viewMoreTag(item)">······</span>
                                    </template>
                                </td>
                                <td>
                                    <el-radio-group
                                        v-model="item.is_discount"
                                        style="margin-left:0;"
                                        @change="enterprisePriceIsDiscount(item)"
                                        :disabled="vueFormParams.IsRead"
                                    >
                                        <el-radio :label="true">
                                            是
                                        </el-radio>
                                        <el-radio :label="false">
                                            否
                                        </el-radio>
                                    </el-radio-group>
                                    <p class="pStyle3">
                                        优惠比例
                                        <input
                                            type="text"
                                            v-model="item.discount_rate"
                                            class="inputStyle2"
                                            :disabled="!item.is_discount||vueFormParams.IsRead"
                                            @input="valueEnterprisePrice(item)"
                                        >%
                                    </p>
                                </td>
                                <td>
                                    <el-button
                                        type="danger"
                                        plain
                                        size="small"
                                        @click="delectPrice(item)"
                                        v-if="!vueFormParams.IsRead"
                                    >
                                        删除
                                    </el-button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <button
                    class="addBtn el-icon-plus"
                    @click="selectEnterpriseInformationPrice"
                    v-if="!vueFormParams.IsRead"
                >
                    点击此处新增
                </button>
            </div>
        </template>
        <template v-if="vueFormParams.IsRead">
            <div class="marketInformationPrice">
                <table>
                    <thead>
                        <tr>
                            <th colspan="11">
                                产品信息价格
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>价格模式</th>
                            <th>区域信息价</th>
                            <th>规格型号</th>
                            <th>市场价格</th>
                            <th>优惠比例(%)</th>
                            <th>优惠金额(元)</th>
                            <th>含税单价(元)</th>
                            <th>税率(%)</th>
                            <th>除税单价(元)</th>
                            <th>附加型号</th>
                            <th>额外可用<br>附加型号</th>
                        </tr>
                        <template v-for="(item,index) in formData.ContractPriceMarkNumList">
                            <tr :key="index" :class="{ infoPriceBg: item.price_type == 1, enterprisePriceBg: item.price_type == 0 }">
                                <td>
                                    <template v-if="item.price_type == 0">
                                        固定价
                                    </template>
                                    <template v-else>
                                        信息价
                                    </template>
                                </td>
                                <td>{{ item.region_name }}</td>
                                <td>{{ item.specification_mode_name }}</td>
                                <td>{{ item.market_price }}</td>
                                <td>{{ item.discount_rate }}</td>
                                <td>{{ item.discount_money }}</td>
                                <td>{{ item.included_tax_unit_price }}</td>
                                <td>{{ item.tax_rate }}</td>
                                <td>{{ item.after_tax_unit_price }}</td>
                                <td
                                    @click="selectModels(item, 'seeScene')"
                                    style="color: #409EFF; cursor: pointer;"
                                >
                                    ······
                                </td>
                                <td>
                                    <template v-if="item.additionalModels">
                                        <span class="tag-more-text" @click="viewMoreTag(item)">······</span>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </template>
        <div class="additionalModels">
            <table>
                <thead>
                    <tr>
                        <th colspan="8">
                            附加型号设置
                        </th>
                    </tr>
                </thead>
                <template v-for="(item,index) in formData.ContractPriceAdditionalList">
                    <tbody :key="index">
                        <tr>
                            <th>附加型号</th>
                            <td class="tdStyle2">
                                {{ item.additional_model }}
                            </td>
                            <th>价格</th>
                            <td>
                                <el-input
                                    v-model="item.included_tax_unit_price"
                                    @input="calculatePreferentialPrice(item)"
                                    :disabled="vueFormParams.IsRead"
                                ></el-input>
                            </td>
                            <th>优惠后价格</th>
                            <td class="tdStyle2">
                                {{ item.additional_model_price }}
                            </td>
                            <td>
                                <el-checkbox-group
                                    v-model="item.checkAll"
                                    @change="additionalModelsAllLabels(item)"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <el-checkbox label="所有标号"></el-checkbox>
                                </el-checkbox-group>
                            </td>
                            <td rowspan="3" class="tdStyle1">
                                <el-button
                                    type="danger"
                                    plain
                                    size="small"
                                    @click="deleteAdditionalModels(item,index)"
                                    v-if="!vueFormParams.IsRead"
                                >
                                    删除
                                </el-button>
                            </td>
                        </tr>
                        <tr v-if="!item.checkAll">
                            <th>产品标号</th>
                            <td colspan="6">
                                <el-checkbox-group v-model="item.checkedProductNumList" :disabled="vueFormParams.IsRead">
                                    <template v-for="(k, i) in item.productNumList">
                                        <el-checkbox
                                            :key="i"
                                            :label="k.compose_code"
                                            @change="additionalModelselectionLabel(item, k ,$event)"
                                        >
                                            {{ k.specification_mode_name }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr>
                            <th>是否优惠</th>
                            <td colspan="6">
                                <el-radio-group
                                    v-model="item.is_discount"
                                    @change="additionalModelsIsDiscount(item)"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                                <p class="pStyle1">
                                    优惠比例±
                                    <input
                                        type="text"
                                        v-model="item.discount_rate"
                                        :disabled="!item.is_discount||vueFormParams.IsRead"
                                        class="inputStyle2"
                                        @input="calculatePreferentialPrice(item)"
                                    >
                                    %
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </table>
            <button class="addBtn el-icon-plus" @click="selectAdditionalModels" v-if="!vueFormParams.IsRead">
                点击此处新增
            </button>
        </div>
        <div class="pouringMethod">
            <table>
                <thead>
                    <tr>
                        <th colspan="6">
                            浇筑方式设置
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>地泵(元/m³)</th>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.ground_pump_price"
                                type="number"
                                @mousewheel.native.prevent
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                        <th>自备泵(元/m³)</th>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.self_owned_pump"
                                type="number"
                                @mousewheel.native.prevent
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                        <th>吊斗(元/m³)</th>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.cableway_bucket"
                                type="number"
                                @mousewheel.native.prevent
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>泵送补充模式</th>
                        <td colspan="5">
                            <el-checkbox
                                v-model="formData.pumper_money_mode"
                                :true-label="1"
                                :false-label="0"
                                class="checkboxStyle1"
                                :disabled="vueFormParams.IsRead"
                            >
                                加收出场费
                                <p class="pStyle2">
                                    不足
                                    <el-input
                                        placeholder="请输入"
                                        class="inputStyle1"
                                        v-model="formData.pumper_volumn_min"
                                        type="number"
                                        :disabled="vueFormParams.IsRead"
                                    ></el-input>
                                    方，收出场费
                                    <el-input
                                        placeholder="请输入"
                                        class="inputStyle1"
                                        v-model="formData.pumper_add_money"
                                        type="number"
                                        :disabled="vueFormParams.IsRead"
                                    ></el-input>元
                                </p>
                            </el-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <th rowspan="2">
                            优惠项目
                        </th>
                        <td colspan="5">
                            <el-radio-group v-model="formData.pump_discounts_type" :disabled="vueFormParams.IsRead">
                                <el-radio :label="0">
                                    每月减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.pump_month_derate"
                                        :disabled="formData.pump_discounts_type == 1||vueFormParams.IsRead"
                                        type="number"
                                        @mousewheel.native.prevent
                                    ></el-input>
                                    次小方量泵送费（泵车进场费）
                                </el-radio>
                                <el-radio :label="1">
                                    按合同共减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.pump_all_derate"
                                        :disabled="formData.pump_discounts_type == 0||vueFormParams.IsRead"
                                        type="number"
                                        @mousewheel.native.prevent
                                    ></el-input>
                                    次小方量泵送费（泵车进场费）
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <el-radio-group v-model="formData.special_pump_discounts_type" :disabled="vueFormParams.IsRead">
                                <el-radio :label="0">
                                    每月减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.special_pump_month_derate"
                                        :disabled="formData.special_pump_discounts_type == 1||vueFormParams.IsRead"
                                        type="number"
                                        @mousewheel.native.prevent
                                    ></el-input>
                                    次特殊泵种费
                                </el-radio>
                                <el-radio :label="1">
                                    按合同共减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.special_pump_all_derate"
                                        :disabled="formData.special_pump_discounts_type == 0||vueFormParams.IsRead"
                                        type="number"
                                        @mousewheel.native.prevent
                                    ></el-input>
                                    次特殊泵种费
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="tdStyle3">
                            <div style="display: flex; justify-content: space-around;">
                                <el-radio-group v-model="formData.is_pump_base" :disabled="vueFormParams.IsRead">
                                    <el-radio :label="true">
                                        统一汽车泵价格
                                        <el-input
                                            placeholder="请输入"
                                            class="inputStyle1"
                                            v-model="formData.pump_price"
                                            type="number"
                                            :disabled="!formData.is_pump_base"
                                            @mousewheel.native.prevent
                                        ></el-input>
                                        元/m³
                                    </el-radio>
                                    <el-radio :label="false">
                                        泵送高度区间配置
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table v-if="!formData.is_pump_base&&formData.is_pump_base!==null">
                <tbody>
                    <tr>
                        <th>泵送高度(m)</th>
                        <th>泵送价格(元/m³)</th>
                    </tr>
                    <tr v-for="(pump, index) in formData.ContractPricePumpList" :key="pump.cppiid + index">
                        <td class="pump-select cell-center">
                            <el-input class="inputStyle1" v-model="pump.pump_lower" readonly></el-input>
                            <span class="spanStyle1">-</span>
                            <el-input class="inputStyle1" v-model="pump.pump_upper" readonly></el-input>
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="pump.pump_price"
                                type="number"
                                @mousewheel.native.prevent
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="transportationCost">
            <table>
                <thead>
                    <tr>
                        <th colspan="6">
                            运输费用设置
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>我方运输</th>
                        <td colspan="2">
                            <el-radio-group v-model="formData.is_self_carriage" :disabled="vueFormParams.IsRead">
                                <el-radio :label="true">
                                    是
                                </el-radio>
                                <el-radio :label="false">
                                    否
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <th>是否含基础运费</th>
                        <td colspan="2">
                            <el-radio-group v-model="formData.is_included_freight" :disabled="vueFormParams.IsRead">
                                <el-radio :label="true">
                                    是
                                </el-radio>
                                <el-radio :label="false">
                                    否
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>基础运费(元/m³)</th>
                        <td>
                            <el-input
                                placeholder="请输入内容"
                                v-model="formData.base_freight"
                                type="number"
                                @mousewheel.native.prevent
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                        <th>基础公里数(km)</th>
                        <td>
                            <el-input
                                placeholder="请输入内容"
                                v-model="formData.base_mileage"
                                type="number"
                                @mousewheel.native.prevent
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                        <th>超出运距费用(元/km/m³)</th>
                        <td>
                            <el-input
                                placeholder="请输入内容"
                                v-model="formData.than_distance"
                                type="number"
                                @mousewheel.native.prevent
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>运费补充模式</th>
                        <td colspan="5">
                            <el-radio-group v-model="formData.compensate_charge_way" :disabled="vueFormParams.IsRead">
                                <el-radio :label="1">
                                    补方
                                    <p class="pStyle1">
                                        每车不足
                                        <el-input
                                            placeholder="请输入"
                                            v-model="formData.compensate_capacity"
                                            :disabled="formData.compensate_charge_way == 2||vueFormParams.IsRead"
                                            type="number"
                                            @mousewheel.native.prevent
                                            class="inputStyle1"
                                        ></el-input> 方，按
                                        <el-input
                                            placeholder="请输入"
                                            v-model="formData.bv_volumn_add"
                                            :disabled="formData.compensate_charge_way == 2||vueFormParams.IsRead"
                                            class="inputStyle1"
                                            type="number"
                                            @mousewheel.native.prevent
                                        ></el-input> 方收取运费。
                                    </p>
                                </el-radio>
                                <el-radio :label="2">
                                    补固定金额
                                    <p class="pStyle1">
                                        单次方不足
                                        <el-input
                                            placeholder="请输入"
                                            class="inputStyle1"
                                            v-model="formData.bp_volumn_less"
                                            :disabled="formData.compensate_charge_way == 1||vueFormParams.IsRead"
                                            type="number"
                                            @mousewheel.native.prevent
                                        ></el-input>
                                        方,按实际方量价格结算后加收
                                        <el-input
                                            placeholder="请输入"
                                            class="inputStyle1"
                                            v-model="formData.plus_money"
                                            :disabled="formData.compensate_charge_way == 1||vueFormParams.IsRead"
                                            type="number"
                                            @mousewheel.native.prevent
                                        ></el-input> 元。
                                        <el-switch
                                            :disabled="formData.compensate_charge_way == 1||vueFormParams.IsRead"
                                            v-model="formData.bp_volumn_is_no_frist"
                                            active-text="首次不收费，二次正常收费"
                                        >
                                        </el-switch>
                                    </p>
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>对方自运优惠(元/m³)</th>
                        <td colspan="2">
                            <el-input v-model="formData.transport_price" placeholder="请输入内容" :disabled="vueFormParams.IsRead"></el-input>
                        </td>
                        <th>拉水(元/车)</th>
                        <td colspan="2">
                            <el-input v-model="formData.pull_water" placeholder="请输入内容" :disabled="vueFormParams.IsRead"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>优惠项目</th>
                        <td colspan="5">
                            <el-radio-group v-model="formData.freight_discounts_type" :disabled="vueFormParams.IsRead">
                                <el-radio :label="0">
                                    每月减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.freight_month_derate"
                                        :disabled="formData.freight_discounts_type == 1||vueFormParams.IsRead"
                                        type="number"
                                        @mousewheel.native.prevent
                                    ></el-input>
                                    次小方量运费
                                </el-radio>
                                <el-radio :label="1">
                                    按合同共减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.freight_all_derate"
                                        :disabled="formData.freight_discounts_type == 0||vueFormParams.IsRead"
                                        type="number"
                                        @mousewheel.native.prevent
                                    ></el-input>
                                    次小方量运费
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="30%"
            height="30%"
            :modal="false"
            center
        >
            <ul>
                <li
                    class="tag el_left"
                    v-for="(tag,i) in currentNumMore.additionalModels"
                    :key="i"
                >
                    <span class="tag-text" :title="tag.additional_model">{{ tag.additional_model }}</span>
                    <span
                        class="iconfont iconguanbi"
                        @click="removeAdditionalModels(tag,i,currentNumMore)"
                        v-if="!vueFormParams.IsRead"
                    ></span>
                </li>
            </ul>
        </el-dialog>
    </div>
</template>

<script>
import priceSetting from './js/priceSetting.js';
import { toFixedFun } from '@/assets/js/decimals';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

// 默认 泵送高度区间配置  首次添加页面初始值
const defaultContractPricePumpList = [{
    cppiid: '',
    pump_height_name: '18——60',
    pump_lower: 18,
    pump_lower_code: '303-BS0-01',
    pump_price: '10',
    pump_upper: '60',
    pump_upper_code: '303-BS0-05',
    sort_num: 31,
}, {
    cppiid: '',
    pump_height_name: '62——70',
    pump_lower: '62',
    pump_lower_code: '303-BS0-06',
    pump_price: '30',
    pump_upper: '70',
    pump_upper_code: '303-BS0-10',
    sort_num: 32,
}];
export default {
    name: 'price-setting-form-kj',
    components: {},
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            taxRateData: [{
                name: '3%',
                val: 3,
            }, {
                name: '6%',
                val: 6,
            }, {
                name: '13%',
                val: 13,
            }],
            // 表单数据
            formData: {
                no_pump_minus_money: 10,
                pump_add_money: 10,
                compensate_charge_way: 2,
                bp_volumn_is_no_frist: true,
                is_base_price_adjusted: false,
                ContractPriceMarkNumList: [],
                ContractPriceAdditionalList: [],
                ContractPriceAdditionalMarkList: [],
                ContractPricePumpList: [],
            },
            // 市场信息价数据
            marketValueData: [],
            // 企业固定价数据
            enterprisePriceData: [],
            // 合同优惠比例-是否选择按泵送优惠
            isPreferentialWay: false,
            // 区域数据
            regionData: [],
            // 期次下拉数据
            periodData: [],
            // 基准价产品型号数据
            productModelData: [],
            // 泵送字典数据列表
            pumpingHeightSelectData: [],
            IsRead: false,
            dialogVisible: false, // 更多附加型号弹框显示隐藏
            currentNumMore: {}, // 当前要弹出更多型号的标号数据
            dialogTitle: '', // 更多附加型号标题
        };
    },
    watch: {
        'formData.pump_discounts_mode': {
            handler() {
                this.selectMarketInformationPriceCallback();
            },
            deep: true,
        },
    },
    computed: {},
    created() {
        if (this.vueFormParams.formId) {
            this.vueFormParams.PKValues = this.vueFormParams.formId;
        }
        this.loading = true;
        // 获取泵送字典价格
        this.getPumpList();
        // 获取区域数据
        this.getRegionData();
    },
    mounted() {
        // 获取价格表
        this.getContractPrice();
    },
    methods: {
        // 获取价格表
        getContractPrice() {
            let queryUrl = '';
            if (this.vueFormParams.businessInstanceId) {
                queryUrl += '?businessInstanceId=' + this.vueFormParams.businessInstanceId;
                if (this.vueFormParams.PKValues) {
                    queryUrl += '&PKValues=' + this.vueFormParams.PKValues;
                }
            } else if (this.vueFormParams.PKValues) {
                queryUrl += '?PKValues=' + this.vueFormParams.PKValues;
                if (this.vueFormParams.code) {
                    if (this.vueFormParams.code.term) {
                        const originalContractVersionId = this.$queryString(this.vueFormParams.code.term, 'originalContractVersionId');
                        if (originalContractVersionId) {
                            queryUrl += '&originalContractVersionId=' + originalContractVersionId;
                        }
                    }
                }
            }
            this.$axios
                .get('/interfaceApi/sale/contract_price/info_price_instance' + queryUrl)
                .then(res => {
                    if (res) {
                        this.formData = res;
                        if (!this.formData.no_pump_minus_money) {
                            this.formData.no_pump_minus_money = 10;
                        }
                        if (!this.formData.pump_add_money) {
                            this.formData.pump_add_money = 10;
                        }
                        if (!this.formData.compensate_charge_way) {
                            this.formData.compensate_charge_way = 2;
                        }
                        if (!this.formData.bp_volumn_is_no_frist) {
                            this.formData.bp_volumn_is_no_frist = true;
                        }
                        if (this.formData.is_base_price_adjusted === null) {
                            this.formData.is_base_price_adjusted = false;
                        }
                        // 初始化处理数据
                        this.analysisRelationship();
                        // 地区信息价有数据，拉取期次数据
                        if (this.formData.info_price_regional_number && this.formData.info_price_regional_number !== '') {
                            // 获取期次数据
                            this.getPeriodData(this.formData.info_price_regional_number);
                        }
                        if (this.formData.pumper_money_mode === null) {
                            this.formData.pumper_money_mode = 1;
                        }
                        // 初始化泵送高度区间配置
                        if (this.formData.ContractPricePumpList === null || this.formData.ContractPricePumpList.length === 0) {
                            this.formData.ContractPricePumpList = defaultContractPricePumpList;
                        }
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 初始化数据
        analysisRelationship() {
            // 初始化判断是否有关联关系
            if ((this.formData.ContractPriceAdditionalMarkList || []).length > 0) {
                // 初始化 信息价根据关联关系，添加关联附加型号
                this.formData.ContractPriceMarkNumList.forEach(k => {
                    k.additionalModels = [];
                    this.formData.ContractPriceAdditionalMarkList.forEach(item => {
                        if (item.compose_code === k.compose_code) {
                            k.additionalModels.push({
                                additional_model: item.additional_model,
                                additional_model_code: item.additional_model_code,
                                id: item.cpaiid,
                            });
                        }
                    });
                });

                // 初始化 附加型号根据关联关系，绑定关联的信息价
                this.formData.ContractPriceAdditionalList.forEach(z => {
                    // 自定义-选中的标号集合
                    z.checkedProductNumList = [];
                    // 自定义-标号集合
                    z.productNumList = JSON.parse(JSON.stringify(this.formData.ContractPriceMarkNumList));
                    this.formData.ContractPriceAdditionalMarkList.forEach(item => {
                        if (item.additional_model_code === z.additional_model_code) {
                            z.checkedProductNumList.push(item.compose_code);
                        }
                    });
                    // 自定义-是否是所有型号
                    if (z.checkedProductNumList.length === z.productNumList.length) {
                        z.checkAll = true;
                    } else {
                        z.checkAll = false;
                    }
                });
            } else {
                if ((this.formData.ContractPriceMarkNumList || []).length > 0) {
                    this.formData.ContractPriceMarkNumList.forEach(k => {
                        k.additionalModels = [];
                    });
                }
                if ((this.formData.ContractPriceAdditionalList || []).length > 0) {
                    this.formData.ContractPriceAdditionalList.forEach(z => {
                        // 自定义-是否是所有型号
                        z.checkAll = false;
                        // 自定义-选中的标号集合
                        z.checkedProductNumList = [];
                        // 自定义-标号集合
                        z.productNumList = JSON.parse(JSON.stringify(this.formData.ContractPriceMarkNumList));
                    });
                }
            }

            // 初始化是否已选地区，已选地区拉取当前地区关联期次数据
            if (this.formData.info_price_regional_number) {
                // 获取期次数据
                this.getPeriodData(this.formData.info_price_regional_number);
            }
            // 初始化是否已选期次，已选期次数据拉取当前期次数据基准价产品型号数据
            if (this.formData.base_price_period_number) {
                // 获取基准价产品型号数据
                this.getProductModelData(this.formData.base_price_period_number);
            }
        },

        // 获取区域数据
        getRegionData() {
            this.$axios
                .get('/interfaceApi/sale/info_price/get_regional_list')
                .then(res => {
                    if (res) {
                        this.regionData = res;
                    } else {
                        this.regionData = [];
                    }
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择区域
        selectArea() {
            const row = this.regionData.filter(item => item.region_code === this.formData.info_price_regional_number);
            this.formData.info_price_regional_name = row[0].region_name;

            this.formData.base_price_period_number = '';
            this.formData.base_price_period = '';
            this.formData.base_info_price_number = '';
            this.formData.base_specification_mode_name = '';
            this.formData.base_price = null;

            // 获取期次数据
            this.getPeriodData(this.formData.info_price_regional_number);
        },

        // 选择期次 获取焦点
        selectPeriodFocus() {
            if (this.formData.info_price_regional_number === '') {
                this.$message({
                    message: '请先选择区域！',
                    type: 'warning',
                });
            }
        },
        // 获取期次数据
        getPeriodData(areaId) {
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_period_list?regionalNumber=${areaId}`)
                .then(res => {
                    if (res) {
                        this.periodData = res;
                    } else {
                        this.periodData = [];
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择期次
        selectPeriod() {
            const row = this.periodData.filter(item => item.info_price_period_number === this.formData.base_price_period_number);
            this.formData.base_price_period = row[0].current_period;

            this.formData.base_info_price_number = '';
            this.formData.base_specification_mode_name = '';
            this.formData.base_price = null;

            // 获取基准价产品型号数据
            this.getProductModelData(this.formData.base_price_period_number);
        },

        // 选择基准产品型号 获取焦点
        selectProductModelFocus() {
            if (this.formData.base_price_period_number === '') {
                this.$message({
                    message: '请先选择期次！',
                    type: 'warning',
                });
            }
        },
        // 获取基准价产品型号数据
        getProductModelData(periodNumber) {
            this.productModelData = [];
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_period_extend_list?periodNumber=${periodNumber}`)
                .then(res => {
                    if (res) {
                        const option = {
                            product_mark_num: '全部',
                            product_mark_code: '',
                        };
                        this.productModelData.push(option, ...res);
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择基准产品型号
        selectProductModel() {
            const row = this.productModelData.filter(item => item.info_price_number === this.formData.base_info_price_number);
            this.formData.base_specification_mode_name = row[0].specification_mode_name;
            this.formData.base_price = row[0].including_tax_price;
        },

        // 选择市场信息价
        selectMarketInformationPrice() {
            if ((this.formData.info_price_regional_number || '') === '') {
                this.$message({
                    message: '请先选择区域！',
                    type: 'warning',
                });
            } else if (this.formData.is_base_price_adjusted && !this.formData.base_price_period_number) {
                this.$message({
                    message: '请先选择期次！',
                    type: 'warning',
                });
            } else {
                const requestUrl = '/contract_price/info_price_mark_num_list';
                const requestParameter = {
                    info_price_regional_number: this.formData.info_price_regional_number,
                    info_price_period_number: this.formData.base_price_period_number,
                    is_query_additional_model: false,
                };
                this.$toast({
                    title: true,
                    text: '市场价格信息',
                    type: 'eject',
                    width: '15rem',
                    height: '90%',
                    t_url: 'salesBusiness/priceSetting/selectMarketInformationPrice',
                    extr: {
                        tips: '市场价格信息',
                        requestUrl: requestUrl,
                        requestParameter: requestParameter,
                        sureCallBack: this.selectMarketInformationPriceCallback,
                        // 是否跨区域
                        is_trans_regional: this.formData.is_trans_regional,
                        // 是否需要获取期次 = 按基准产品单价浮动
                        isPeriodRequired: this.formData.is_base_price_adjusted,
                    },
                });
            }
        },
        // 选择市场信息价回调【选择优惠方式(按泵送优惠/按非泵送优惠)、信息价取整，没有返回值data】
        selectMarketInformationPriceCallback(data, periodData) {
            // 不按基准期次价格浮动调整， 回调期次赋值
            if (!this.formData.is_base_price_adjusted) {
                this.formData.base_price_period_number = periodData.basePricePeriodNumber || '';
                this.formData.base_price_period = periodData.basePricePeriodName || '';
            }
            // 先判断有没有已经选择过的
            const opt = {
                arr: this.formData.ContractPriceMarkNumList,
                targetArr: data,
                prop: 'compose_code',
                targetProp: 'compose_code',
                msgProp: 'specification_mode_name',
            };
            data = priceSetting.checkRemoveRepeat(this, opt);
            let isPreferential = false;
            // 处理市场信息价数据
            this.processingInfoPriceData();
            this.formData.ContractPriceMarkNumList = [];
            if (!data) {
                data = this.marketValueData;
                isPreferential = true;
            } else {
                this.formData.ContractPriceMarkNumList.push(...this.marketValueData);
                isPreferential = false;
            }
            const selectData = [];
            // 0按含税单价, 1按除税单价
            const discount_rate_type = this.formData.discount_rate_type;
            // 合同优惠比例-是否选择按泵送优惠
            const pump_discounts_mode = this.formData.pump_discounts_mode;
            if (pump_discounts_mode) {
                this.isPreferentialWay = true;
            } else {
                this.isPreferentialWay = false;
            }
            let informationPriceValue = {};
            data.forEach(item => {
                if ( this.isPreferentialWay === false || item.pouring === '') { // 没有选择按泵送优惠或者数据没有泵送和非泵送
                    // 按含税单价
                    if (discount_rate_type === 0) {
                        // 信息价取值(按含税单价)
                        informationPriceValue = this.informationPriceValue_taxExclusion(item);
                    } else {
                        // 信息价取值(按除税单价)
                        informationPriceValue = this.informationPriceValue_taxIncluded(item);
                    }
                } else if (pump_discounts_mode === 1) {
                    // 仅泵送优惠
                    informationPriceValue = this.informationPriceValue_PumpingDiscount(selectData, item);
                } else if (pump_discounts_mode === 2) {
                    // 仅非泵送优惠
                    informationPriceValue = this.informationPriceValue_nonPumpingDiscount(selectData, item);
                }
                // 优惠金额
                let newDiscount_money = 0;
                let newCpmiid = '';
                if (isPreferential) {
                    newCpmiid = item.cpmiid;
                    newDiscount_money = item.discount_money;
                } else {
                    newCpmiid = item.id;
                }
                selectData.push({
                    cpmiid: newCpmiid,
                    InfoPriceAdditionalList: item.InfoPriceAdditionalList || [],
                    product_mark_code: item.product_mark_code,
                    product_mark_num: item.product_mark_num,
                    product_mark_price: 0,
                    sort_num: 0,
                    info_price_period_number: item.info_price_period_number,
                    info_price_number: item.info_price_number,
                    info_price_name: item.info_price_name,
                    additional_model_code: item.additional_model_code,
                    additional_model: item.additional_model,
                    coarse_aggregate_code: item.coarse_aggregate_code,
                    coarse_aggregate: item.coarse_aggregate,
                    fine_aggregate_code: item.fine_aggregate_code,
                    fine_aggregate: item.fine_aggregate,
                    //  市场价格
                    market_price: informationPriceValue.new_market_price,
                    // 优惠比例
                    discount_rate: informationPriceValue.initDiscountRate,
                    // 优惠金额
                    discount_money: newDiscount_money,
                    // 含税单价
                    included_tax_unit_price: informationPriceValue.new_included_tax_unit_price,
                    // 税率
                    tax_rate: this.formData.tax_rate,
                    // 除税单价
                    after_tax_unit_price: informationPriceValue.new_after_tax_unit_price,
                    pouring: item.pouring,
                    pouring_name: item.pouring_name,
                    price_type: 1,
                    is_discount: true,
                    // 编码组合
                    compose_code: item.compose_code,
                    // 规格型号名称
                    specification_mode_name: item.specification_mode_name,
                    // 自定义-额外型号
                    additionalModels: this.addedPrice(),
                    region_name: item.region_name,
                    region_code: item.region_code,
                });
            });
            if (isPreferential === false) {
                // 添加价（市场价，企业固定价）时，有已选附加型号，附加型号关联添加价
                this.associatedAdditionalModels(selectData);
            }

            this.formData.ContractPriceMarkNumList.push(...selectData, ...this.enterprisePriceData);
            this.formData.ContractPriceMarkNumList.sort((a, b) => {
                let returnValue = null;
                if (a.product_mark_num > b.product_mark_num) {
                    returnValue = 1;
                } if (a.product_mark_num === b.product_mark_num) {
                    returnValue = Number(b.pouring || 0) - Number(a.pouring || 0);
                } if (a.product_mark_num < b.product_mark_num) {
                    returnValue = -1;
                }
                return returnValue;
            });
        },
        // 信息价取值(按含税单价)
        informationPriceValue_taxExclusion(data) {
            const newValue = {
                // 优惠比例
                initDiscountRate: 0,
                // 市场价格
                new_market_price: 0,
                // 含税单价
                new_included_tax_unit_price: 0,
                // 除税单价
                new_after_tax_unit_price: 0,
            };
                // 优惠金额
            let new_discount_money = 0;
            if (data.discount_money) {
                new_discount_money = Number(data.discount_money);
            }

            if (data.discount_rate === '') { // discount_rate为空，修改清空优惠比例
                newValue.initDiscountRate = 0;
            } else if (!data.discount_rate && data.discount_rate !== 0) {
                newValue.initDiscountRate = this.formData.discount_rate || 0;
            } else {
                newValue.initDiscountRate = data.discount_rate;
            }

            newValue.new_market_price = data.market_price || 0;

            if (newValue.initDiscountRate && newValue.initDiscountRate !== 0) {
                // eslint-disable-next-line max-len
                const formula = NP.minus(newValue.new_market_price, NP.times(newValue.new_market_price, NP.divide(newValue.initDiscountRate || 0, 100)), new_discount_money);
                newValue.new_included_tax_unit_price = this.numericalCalculation(formula);
            } else {
                newValue.new_included_tax_unit_price = NP.minus(newValue.new_market_price, new_discount_money);
            }

            const new_formula = NP.divide(newValue.new_included_tax_unit_price, NP.plus(1, NP.divide(this.formData.tax_rate || 0, 100)));
            newValue.new_after_tax_unit_price = this.numericalCalculation(new_formula);
            return newValue;
        },
        // 信息价取值(按除税单价)
        informationPriceValue_taxIncluded(data) {
            const newValue = {
                // 优惠比例
                initDiscountRate: 0,
                // 市场价格
                new_market_price: data.market_price || 0,
                // 含税单价
                new_included_tax_unit_price: 0,
                // 除税单价
                new_after_tax_unit_price: 0,
            };

            if (data.discount_rate === '') { // discount_rate为空，修改清空优惠比例
                newValue.initDiscountRate = 0;
            } else if (!data.discount_rate && data.discount_rate !== 0) {
                newValue.initDiscountRate = this.formData.discount_rate || 0;
            } else {
                newValue.initDiscountRate = data.discount_rate;
            }

            // 除税单价-优惠前
            const unitPriceExcludingTax_beforeDiscount = NP.divide(data.market_price || 0, NP.plus(1, NP.divide(this.formData.tax_rate || 0, 100)));
            // 除税单价-优惠后
            // eslint-disable-next-line max-len
            const unitPriceExcludingTax_afterTheDiscount = Math.floor(NP.times(unitPriceExcludingTax_beforeDiscount, NP.minus(1, NP.divide(newValue.initDiscountRate || 0, 100))));
            newValue.new_after_tax_unit_price = unitPriceExcludingTax_afterTheDiscount;

            // 含税单价
            const formula_taxIncluded = NP.times(newValue.new_after_tax_unit_price, NP.plus(1, NP.divide(this.formData.tax_rate || 0, 100)));
            newValue.new_included_tax_unit_price = this.numericalCalculation(formula_taxIncluded);

            return newValue;
        },

        // 信息价取值(仅泵送优惠)
        informationPriceValue_PumpingDiscount(data, item) {
            const newValue = {
                // 优惠比例
                initDiscountRate: 0,
                // 市场价格
                new_market_price: item.market_price,
                // 含税单价
                new_included_tax_unit_price: 0,
                // 除税单价
                new_after_tax_unit_price: 0,
            };
                // 优惠金额
            let new_discount_money = 0;
            if (item.discount_money) {
                new_discount_money = Number(item.discount_money);
            }
            if (item.pouring === '1') { // 泵送
                if (item.discount_rate === '') { // discount_rate为空，修改清空优惠比例
                    newValue.initDiscountRate = 0;
                } else if (!item.discount_rate && item.discount_rate !== 0) {
                    newValue.initDiscountRate = this.formData.discount_rate || 0;
                } else {
                    newValue.initDiscountRate = item.discount_rate;
                }
                // eslint-disable-next-line max-len
                const formula = NP.minus(newValue.new_market_price, NP.times(newValue.new_market_price, NP.divide(newValue.initDiscountRate || 0, 100)), new_discount_money);
                newValue.new_included_tax_unit_price = this.numericalCalculation(formula);

                // 获取当前选择的和当前信息价产品标号相同的所有信息价
                // eslint-disable-next-line max-len
                const row_currentSelection = data.filter(k => k.product_mark_code === item.product_mark_code && k.additional_model_code === item.additional_model_code );
                // 获取已选的和当前信息价产品标号相同的所有信息价
                // eslint-disable-next-line max-len
                const row_selected = this.formData.ContractPriceMarkNumList.filter(k => k.product_mark_code === item.product_mark_code && k.additional_model_code === item.additional_model_code );
                // 和当前信息价产品标号相同的所有信息价
                const row = row_currentSelection.concat(row_selected);
                // 过滤出和当前非泵送信息价产品标号相同的泵送信息价
                const newRow = row.filter(k => k.pouring === '0' );
                // 判断有与当前非泵送信息价产品标号相同的泵送信息价，当前非泵送信息价的含税单价用泵送信息价计算，否者当前非泵送信息价的含税单价等于市场价格
                if (newRow.length > 0) {
                    // eslint-disable-next-line max-len
                    newRow[0].included_tax_unit_price = NP.minus(Number(newValue.new_included_tax_unit_price), Number(this.formData.no_pump_minus_money));
                    // 计算除税单价
                    // eslint-disable-next-line max-len
                    const new_formula = NP.minus(newRow[0].included_tax_unit_price, NP.times(newRow[0].included_tax_unit_price, NP.divide(this.formData.tax_rate || 0, 100)));
                    newRow[0].after_tax_unit_price = this.numericalCalculation(new_formula);
                }
            } else { // 非泵送
                // 获取当前选择的和当前信息价产品标号相同的所有信息价
                // eslint-disable-next-line max-len
                const row_currentSelection = data.filter(k => k.product_mark_code === item.product_mark_code && k.additional_model_code === item.additional_model_code );
                // 获取已选的和当前信息价产品标号相同的所有信息价
                // eslint-disable-next-line max-len
                const row_selected = this.formData.ContractPriceMarkNumList.filter(k => k.product_mark_code === item.product_mark_code && k.additional_model_code === item.additional_model_code );
                // 和当前信息价产品标号相同的所有信息价
                const row = row_currentSelection.concat(row_selected);
                // 过滤出和当前非泵送信息价产品标号相同的泵送信息价
                const newRow = row.filter(k => k.pouring === '1' );
                // 判断有与当前非泵送信息价产品标号相同的泵送信息价，当前非泵送信息价的含税单价用泵送信息价计算，否者当前非泵送信息价的含税单价等于市场价格
                if (newRow.length > 0) {
                    // eslint-disable-next-line max-len
                    newValue.new_included_tax_unit_price = NP.minus(Number(newRow[0].included_tax_unit_price), Number(this.formData.no_pump_minus_money));
                } else {
                    newValue.new_included_tax_unit_price = newValue.new_market_price;
                }
            }
            const new_formula = NP.divide(newValue.new_included_tax_unit_price, NP.plus(1, NP.divide(this.formData.tax_rate || 0, 100)));
            newValue.new_after_tax_unit_price = this.numericalCalculation(new_formula);

            return newValue;
        },
        // 信息价取值(仅非泵送优惠)
        informationPriceValue_nonPumpingDiscount(data, item) {
            const newValue = {
                // 优惠比例
                initDiscountRate: 0,
                // 市场价格
                new_market_price: item.market_price,
                // 含税单价
                new_included_tax_unit_price: 0,
                // 除税单价
                new_after_tax_unit_price: 0,
            };
                // 优惠金额
            let new_discount_money = 0;
            if (item.discount_money) {
                new_discount_money = Number(item.discount_money);
            }
            if (item.pouring === '0') { //  非泵送
                if (item.discount_rate === '') { // discount_rate为空，修改清空优惠比例
                    newValue.initDiscountRate = 0;
                } else if (!item.discount_rate && item.discount_rate !== 0) {
                    newValue.initDiscountRate = this.formData.discount_rate || 0;
                } else {
                    newValue.initDiscountRate = item.discount_rate;
                }
                // eslint-disable-next-line max-len
                const formula = NP.minus(newValue.new_market_price, NP.times(newValue.new_market_price, NP.divide(newValue.initDiscountRate || 0, 100)), new_discount_money);
                newValue.new_included_tax_unit_price = this.numericalCalculation(formula);

                // 获取当前选择的和当前信息价产品标号相同的所有信息价
                // eslint-disable-next-line max-len
                const row_currentSelection = data.filter(k => k.product_mark_code === item.product_mark_code && k.additional_model_code === item.additional_model_code );
                // 获取已选的和当前信息价产品标号相同的所有信息价
                // eslint-disable-next-line max-len
                const row_selected = this.formData.ContractPriceMarkNumList.filter(k => k.product_mark_code === item.product_mark_code && k.additional_model_code === item.additional_model_code );
                // 和当前信息价产品标号相同的所有信息价
                const row = row_currentSelection.concat(row_selected);
                // 过滤出和当前泵送信息价产品标号相同的非泵送信息价
                const newRow = row.filter(k => k.pouring === '1' );
                // 判断有与当前非泵送信息价产品标号相同的泵送信息价，当前非泵送信息价的含税单价用泵送信息价计算，否者当前非泵送信息价的含税单价等于市场价格
                if (newRow.length > 0) {
                    newRow[0].included_tax_unit_price = NP.plus(Number(newValue.new_included_tax_unit_price), Number(this.formData.pump_add_money));
                    // 计算除税单价
                    // eslint-disable-next-line max-len
                    const new_formula = NP.minus(newRow[0].included_tax_unit_price, NP.times(newRow[0].included_tax_unit_price, NP.divide(this.formData.tax_rate || 0, 100)));
                    newRow[0].after_tax_unit_price = this.numericalCalculation(new_formula);
                }
            } else { // 泵送
                // 获取当前选择的和当前信息价产品标号相同的所有信息价
                // eslint-disable-next-line max-len
                const row_currentSelection = data.filter(k => k.product_mark_code === item.product_mark_code && k.additional_model_code === item.additional_model_code );
                // 获取已选的和当前信息价产品标号相同的所有信息价
                // eslint-disable-next-line max-len
                const row_selected = this.formData.ContractPriceMarkNumList.filter(k => k.product_mark_code === item.product_mark_code && k.additional_model_code === item.additional_model_code );
                // 和当前信息价产品标号相同的所有信息价
                const row = row_currentSelection.concat(row_selected);
                // 过滤出和当前泵送信息价产品标号相同的非泵送信息价
                const newRow = row.filter(k => k.pouring === '0' );
                // 判断有与当前非泵送信息价产品标号相同的泵送信息价，当前非泵送信息价的含税单价用泵送信息价计算，否者当前非泵送信息价的含税单价等于市场价格
                if (newRow.length > 0) {
                    newValue.new_included_tax_unit_price = NP.plus(Number(newRow[0].included_tax_unit_price), Number(this.formData.pump_add_money));
                } else {
                    newValue.new_included_tax_unit_price = newValue.new_market_price;
                }
            }
            // 计算除税单价
            const new_formula = NP.divide(newValue.new_included_tax_unit_price, NP.plus(1, NP.divide(this.formData.tax_rate || 0, 100)));
            newValue.new_after_tax_unit_price = this.numericalCalculation(new_formula);

            return newValue;
        },

        // 市场信息价计算
        infoPriceCalculation(data) {
            // 信息价取值(按含税单价)
            let informationPriceValue;
            const selectData = [];
            // 0按含税单价, 1按除税单价
            const discount_rate_type = this.formData.discount_rate_type;
            // 合同优惠比例-是否选择按泵送优惠
            const pump_discounts_mode = this.formData.pump_discounts_mode;
            if ( this.isPreferentialWay === false || data.pouring === '') { // 没有选择按泵送优惠或者数据没有泵送和非泵送
                // 按含税单价
                if (discount_rate_type === 0) {
                    // 信息价取值(按含税单价)
                    informationPriceValue = this.informationPriceValue_taxExclusion(data);
                } else {
                    // 信息价取值(按除税单价)
                    informationPriceValue = this.informationPriceValue_taxIncluded(data);
                }
            } else if (pump_discounts_mode === 1) {
                // 仅泵送优惠
                informationPriceValue = this.informationPriceValue_PumpingDiscount(selectData, data);
            } else if (pump_discounts_mode === 2) {
                // 仅非泵送优惠
                informationPriceValue = this.informationPriceValue_nonPumpingDiscount(selectData, data);
            }

            data.included_tax_unit_price = informationPriceValue.new_included_tax_unit_price;
            data.after_tax_unit_price = informationPriceValue.new_after_tax_unit_price;
        },

        // 市场信息价-选择附加型号
        selectModels(data, type) {
            this.selectModels_InfoPrice = data;
            // 调用选择信息价，传additionalModels值获取附加型号数据
            if (!this.formData.base_price_period_number) {
                this.$message({
                    message: '请先选择期次！',
                    type: 'warning',
                });
            } else {
                const requestUrl = '/contract_price/info_price_mark_num_list';
                const requestParameter = {
                    info_price_regional_number: this.formData.info_price_regional_number,
                    info_price_period_number: this.formData.base_price_period_number,
                    is_query_additional_model: true,
                };
                // 浮动价格计算取值
                const floatValue = this.formData.floating_price_calculated;
                let newDiscount_rate = 0;
                if (!floatValue) { // floatValue=0,附加型号不优惠
                    newDiscount_rate = this.selectModels_InfoPrice.discount_rate;
                } else {
                    newDiscount_rate = this.formData.additional_model_discount_rate;
                }
                const newExtr = {
                    tips: '选择附加型号',
                    requestUrl: requestUrl,
                    requestParameter: requestParameter,
                    isEcho: true,
                    echoData: data.InfoPriceAdditionalList || [],
                    echoFilterFields: 'additional_model_code',
                    sureCallBack: this.selectModelsCallback,
                    // 是否跨区域
                    is_trans_regional: this.formData.is_trans_regional,
                    // 附加型号优惠比例
                    discount_rate: newDiscount_rate,
                    numericalCalculation: this.numericalCalculation,
                };
                if (type === 'seeScene' ) {
                    newExtr.seeScene = true;
                }
                this.$toast({
                    title: true,
                    text: '选择附加型号',
                    type: 'eject',
                    width: '14.4rem',
                    height: '90%',
                    t_url: 'salesBusiness/priceSetting/selectMarketInformationPrice_new',
                    extr: newExtr,
                });
            }
        },

        // 市场信息价-选择附加型号回调
        selectModelsCallback(data) {
            const newAdditionalModels = [];
            data.forEach(item => {
                newAdditionalModels.push({
                    cpmiid: item.product_mark_code || item.cpmiid,
                    cpaiid: item.id || item.cpaiid,
                    additional_model_code: item.additional_model_code,
                    additional_model: item.additional_model,
                    additional_model_price: item.additional_model_price,
                    sort_num: 0,
                    is_discount: true,
                    discount_rate: item.discount_rate,
                    included_tax_unit_price: item.included_tax_unit_price,
                    price_type: 1,
                });
                this.selectModels_InfoPrice.InfoPriceAdditionalList = newAdditionalModels;

                this.formData.ContractPriceAdditionalList.forEach(k => {
                    k.productNumList = JSON.parse(JSON.stringify(this.formData.ContractPriceMarkNumList));
                    if (k.additional_model === item.additional_model && k.additional_model_code === item.additional_model_code) {
                        this.selectModels_InfoPrice.additionalModels.splice(
                            this.selectModels_InfoPrice.additionalModels.findIndex(z => z.additional_model_code === k.additional_model_code),
                            1
                        );

                        if (k.checkAll) {
                            k.checkedProductNumList = this.formData.ContractPriceMarkNumList.map(j => j.compose_code);
                            k.checkedProductNumList.splice(
                                k.checkedProductNumList.findIndex(k => k === this.selectModels_InfoPrice.compose_code),
                                1
                            );
                        }

                        k.productNumList.splice(
                            k.productNumList.findIndex(j => j.compose_code === this.selectModels_InfoPrice.compose_code),
                            1
                        );
                    }
                });
            });
            this.$forceUpdate();
        },

        // 选择税率
        modifyTaxRate(data) {
            this.formData.ContractPriceMarkNumList.forEach(item => {
                item.tax_rate = data;
                const formula = NP.minus(item.included_tax_unit_price, NP.times(item.included_tax_unit_price, NP.divide(data, 100)));
                item.after_tax_unit_price = this.numericalCalculation(formula);
            });
        },

        // 优惠方式（按泵送优惠/按非泵送优惠）单选框选中/取消
        onRadioChange(e, val) {
            if (e.target.localName !== 'input') {
                val === this.formData.pump_discounts_mode ? this.formData.pump_discounts_mode = null : this.formData.pump_discounts_mode = val;
            }
        },

        // 优惠方式（按泵送优惠/按非泵送优惠）优惠值
        preferentialValue() {
            this.selectMarketInformationPriceCallback();
        },

        // 选择企业固定价
        selectEnterpriseInformationPrice() {
            let choosed_code = '';
            this.formData.ContractPriceMarkNumList.map(item => {
                if (item.price_type === 0) {
                    choosed_code += item.product_mark_code + ',';
                }
            });
            this.$toast(
                {
                    title: true,
                    text: '选择产品标号',
                    type: 'eject',
                    width: '15rem',
                    height: '90%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'choose_product_mark',
                            QueryString: 'area_id=' + this.formData.area_id + '&choosed_code=' + choosed_code,
                        },
                        sureCallback: this.productNumCallback,
                    },
                }
            );
        },
        // 选择企业固定价回调
        productNumCallback(data) {
            const selectData = [];
            if (data && data.length > 0) {
                data.forEach(item => {
                    if (!item.attr_price) {
                        item.attr_price = 0;
                    }
                    const formula = NP.divide(item.attr_price, NP.plus(1, NP.divide(this.formData.tax_rate, 100)));
                    const new_after_tax_unit_price = this.numericalCalculation(formula);
                    selectData.push({
                        cpmiid: item.id,
                        product_mark_code: item.smallclass_code,
                        product_mark_num: item.smallclass_name,
                        info_price_name: item.subclass_name,
                        included_tax_unit_price: item.attr_price,
                        product_mark_price_min: item.attr_price_min,
                        // 税率
                        tax_rate: this.formData.tax_rate,
                        // 编码组合
                        compose_code: item.smallclass_code,
                        // 规格型号名称
                        specification_mode_name: item.smallclass_name,
                        // 除税单价
                        after_tax_unit_price: new_after_tax_unit_price,
                        // 优惠后价格
                        product_mark_price: item.attr_price,
                        price_type: 0,
                        is_discount: false,
                        // 优惠比例
                        discount_rate: 0,
                        // 自定义-额外型号
                        additionalModels: this.addedPrice(),
                        sort_num: item.sort_num,
                    });
                });
                // 添加价（市场价，企业固定价）时，有已选附加型号，附加型号关联添加价
                this.associatedAdditionalModels(selectData);
            }
            this.formData.ContractPriceMarkNumList.push(...selectData);
            this.formData.ContractPriceMarkNumList.sort((a, b) => {
                return a.sort_num - b.sort_num;
            });
        },

        // 企业固定价取优惠价值
        valueEnterprisePrice(data) {
            const new_discount_rate = NP.divide(data.discount_rate, 100);
            const formula = NP.minus(data.included_tax_unit_price, NP.times(data.included_tax_unit_price, new_discount_rate));
            data.product_mark_price = this.numericalCalculation(formula);
        },

        // 企业价是否优惠
        enterprisePriceIsDiscount(data) {
            if (data.is_discount ) {
                // 企业固定价取优惠价值
                this.valueEnterprisePrice(data);
            } else {
                data.discount_rate = 0;
                data.product_mark_price = data.included_tax_unit_price;
            }
        },
        // 企业价优惠比例调整
        enterprisePriceCalculation(data) {
            const formula = NP.divide(data.included_tax_unit_price, NP.plus(1, NP.divide(this.formData.tax_rate, 100)));
            data.after_tax_unit_price = this.numericalCalculation(formula);
            if (data.is_discount ) {
                // 企业固定价取优惠价值
                this.valueEnterprisePrice(data);
            } else {
                data.product_mark_price = data.included_tax_unit_price;
            }
        },

        // 企业固定价 调整信息价取整 取值(应为企业固定架数据格式和选择企业固定价返回数据格式完全不一样，所以不能用一个方法)
        enterpriseFixedPriceValue() {
            this.enterprisePriceData.forEach(item => {
                const formula = NP.divide(item.included_tax_unit_price, NP.plus(1, NP.divide(item.tax_rate, 100)));
                const new_after_tax_unit_price = this.numericalCalculation(formula);
                item.after_tax_unit_price = new_after_tax_unit_price;
                if (item.is_discount ) {
                    // 企业固定价取优惠价值
                    this.valueEnterprisePrice(item);
                }
            });
        },

        // 添加价（市场价，企业固定价）时，判断是否有已选附加型号，有就默认添加进去
        addedPrice() {
            const nweAdditionalModels = [];
            // 判断是否有已选的附加型号
            if (this.formData.ContractPriceAdditionalList.length > 0) {
                this.formData.ContractPriceAdditionalList.forEach(item => {
                    nweAdditionalModels.push({
                        additional_model: item.additional_model,
                        additional_model_code: item.additional_model_code,
                        id: item.cpaiid,
                    });
                });
            }
            return nweAdditionalModels;
        },

        // 添加价（市场价，企业固定价）时，有已选附加型号，附加型号关联添加价
        associatedAdditionalModels(data) {
            const new_compose_code = data.map(obj => obj.compose_code);
            this.formData.ContractPriceAdditionalList.forEach(item => {
                item.productNumList.push(...data);
                // 自定义-选中的标号集合
                item.checkedProductNumList.push(...new_compose_code);
                if (item.productNumList.length === item.checkedProductNumList.length) {
                    item.checkAll = true;
                } else {
                    item.checkAll = false;
                }
            });
        },

        // 删除价（市场价，企业固定价）
        delectPrice(data) {
            this.formData.ContractPriceMarkNumList.forEach((item, index) => {
                if (item.cpmiid === data.cpmiid ) {
                    this.formData.ContractPriceMarkNumList.splice(index, 1);
                }
            });
            this.formData.ContractPriceAdditionalList.forEach(item => {
                item.productNumList.splice(item.productNumList.findIndex(item => item.compose_code === data.compose_code), 1);
                item.checkedProductNumList.splice(item.checkedProductNumList.findIndex(item => item === data.compose_code), 1);
                if (item.productNumList.length === item.checkedProductNumList.length) {
                    item.checkAll = true;
                } else {
                    item.checkAll = false;
                }
            });
            this.$forceUpdate();
        },

        // 选择附加型号
        selectAdditionalModels() {
            this.$toast(
                {
                    title: true,
                    text: '选择附加型号',
                    type: 'eject',
                    width: '14.3rem',
                    height: '90%',
                    t_url: 'salesBusiness/selectModel/index',
                    extr: {
                        area_id: this.formData.area_id,
                        sureCallback: this.additionalCallback,
                        multipleCallBack: this.multipleCallBack,
                    },
                }
            );
        },

        // 选取附加型号 - 组合选择回调
        additionalCallback(data) {
            this.$toast({
                title: true,
                text: '附加型号设置',
                type: 'eject',
                width: '50%',
                height: '70%',
                t_url: 'salesBusiness/priceSetting/InfoPriceCombinationSelection',
                extr: {
                    selectData: data,
                    sureCallback: this.additionalModelCombination,
                },
            });
        },

        // 选取附加型号 - 组合选择赋值
        additionalModelCombination(data) {
            // 先判断有没有已经选择过的
            const opt = {
                arr: this.formData.ContractPriceAdditionalList,
                targetArr: [data],
                prop: 'additional_model_code',
                targetProp: 'smallclass_code',
                msgProp: 'smallclass_name',
            };
            data = priceSetting.checkRemoveRepeat(this, opt)[0];
            if (!data) {return;}
            // 新增时，获取所有已选信息价集合
            const nweCheckedProductNumList = [];
            this.formData.ContractPriceMarkNumList.forEach(item => {
                nweCheckedProductNumList.push(item.compose_code);
            });
            this.formData.ContractPriceAdditionalList.push({
                cpaiid: data.id,
                additional_model_code: data.smallclass_code,
                additional_model: data.smallclass_name,
                additional_model_price: data.additional_model_price,
                included_tax_unit_price: data.additional_model_price,
                sort_num: data.sort_num,
                use_count: data.use_count,
                is_discount: false,
                discount_rate: 0,
                // 自定义-是否是所有型号
                checkAll: true,
                // 自定义-选中的标号集合
                checkedProductNumList: nweCheckedProductNumList,
                // 自定义-标号集合
                productNumList: JSON.parse(JSON.stringify(this.formData.ContractPriceMarkNumList)),
            });
            let additionalModels = {};
            additionalModels = {
                additional_model: data.smallclass_name,
                additional_model_code: data.smallclass_code,
                id: data.id,
            };
            this.addAdditionalModels(additionalModels);
            this.formData.ContractPriceAdditionalList.sort((a, b) => {
                if (a.use_count === b.use_count ) {
                    return a.sort_num - b.sort_num;
                }
                return b.use_count - a.use_count;
            });
        },

        // 选取附加型号（完成赋值）- 批量选择
        multipleCallBack(data) {
            // 先判断有没有已经选择过的
            const opt = {
                arr: this.formData.ContractPriceAdditionalList,
                targetArr: data,
                prop: 'additional_model_code',
                targetProp: 'smallclass_code',
                msgProp: 'smallclass_name',
            };
            data = priceSetting.checkRemoveRepeat(this, opt);
            const selectData = [];
            let additionalModels = {};

            let new_discount_rate = 0;
            // 浮动价格计算取值选择的“产品标号+细骨料/粗骨料”及“附加型号，则优惠比例赋值 浮动价格计算取值附加型号优惠比例
            if (this.formData.floating_price_calculated === 1) {
                new_discount_rate = this.formData.additional_model_discount_rate;
            }
            data.forEach(item => {
                additionalModels = {
                    additional_model: item.smallclass_name,
                    additional_model_code: item.smallclass_code,
                    id: item.id,
                };
                this.addAdditionalModels(additionalModels);
                // 新增时，获取所有已选信息价集合
                const nweCheckedProductNumList = this.formData.ContractPriceMarkNumList.map(j => j.compose_code);
                const newProductNumList = JSON.parse(JSON.stringify(this.formData.ContractPriceMarkNumList));

                this.formData.ContractPriceMarkNumList.forEach(x => {
                    if ((x.InfoPriceAdditionalList || []).length > 0) {
                        x.InfoPriceAdditionalList.forEach(k => {
                            if (k.additional_model_code === item.smallclass_code) {
                                x.additionalModels.splice(
                                    x.additionalModels.findIndex(z => z.additional_model_code === k.additional_model_code),
                                    1
                                );

                                nweCheckedProductNumList.splice(
                                    nweCheckedProductNumList.findIndex(d => d === x.compose_code),
                                    1
                                );
                                newProductNumList.splice(
                                    newProductNumList.findIndex(c => c.compose_code === x.compose_code),
                                    1
                                );
                            }
                        });
                    }
                });
                selectData.push({
                    cpaiid: item.id,
                    additional_model_code: item.smallclass_code,
                    additional_model: item.smallclass_name,
                    additional_model_price: item.attr_price,
                    sort_num: item.sort_num,
                    use_count: item.use_count,
                    is_discount: false,
                    // 优惠比例
                    discount_rate: new_discount_rate,
                    included_tax_unit_price: item.attr_price,
                    // 自定义-是否是所有型号
                    checkAll: true,
                    // 自定义-选中的标号集合
                    checkedProductNumList: nweCheckedProductNumList,
                    // 自定义-标号集合
                    productNumList: newProductNumList,
                });
            });
            const selectList = this.formData.ContractPriceAdditionalList.concat(selectData);
            // 按照 use_count desc ,sort_num asc 排序
            selectList.sort((a, b) => {
                if (a.use_count === b.use_count ) {
                    return a.sort_num - b.sort_num;
                }
                return b.use_count - a.use_count;
            });
            this.formData.ContractPriceAdditionalList = selectList;
        },
        // 附加型号所有标号change
        additionalModelsAllLabels(data) {
            if (!data.checkAll) {
                data.checkedProductNumList = [];
                this.formData.ContractPriceMarkNumList.forEach(item => {
                    item.additionalModels.forEach((k, i) => {
                        if (k.additional_model_code === data.additional_model_code) {
                            item.additionalModels.splice(i, 1);
                        }
                    });
                });
            } else {
                data.checkedProductNumList = data.productNumList.map(item => item.compose_code);
                data.productNumList.forEach(k => {
                    this.formData.ContractPriceMarkNumList.forEach(item => {
                        if (k.compose_code === item.compose_code) {
                            item.additionalModels.push({
                                additional_model: data.additional_model,
                                additional_model_code: data.additional_model_code,
                                id: data.cpaiid,
                            });
                        }
                    });
                });
            }
            this.$forceUpdate();
        },
        // 删除附加型号
        deleteAdditionalModels(data, index) {
            this.formData.ContractPriceAdditionalList.splice(index, 1);
            this.formData.ContractPriceMarkNumList.forEach(item => {
                item.additionalModels.forEach((k, i) => {
                    if (k.additional_model_code === data.additional_model_code) {
                        item.additionalModels.splice(i, 1);
                    }
                });
            });
        },
        // 附加型号计算优惠价
        calculatePreferentialPrice(data) {
            // 当前附加型号含税单价
            const price = data.included_tax_unit_price;
            if (data.discount_rate > 0) {
                const formula = NP.minus(price, NP.times(price, NP.divide(data.discount_rate, 100)));
                data.additional_model_price = this.numericalCalculation(formula);
            } else {
                data.additional_model_price = price;
            }
        },
        // 附加型号是否优惠
        additionalModelsIsDiscount(data) {
            if (data.is_discount) {
                // 附加型号计算优惠价
                this.calculatePreferentialPrice(data);
            } else {
                data.additional_model_price = data.included_tax_unit_price;
            }
        },

        // 附加型号取值-调整新信息价取整
        additionalModelValue() {
            this.formData.ContractPriceAdditionalList.forEach(item => {
                if (item.is_discount) {
                    // 附加型号计算优惠价
                    this.calculatePreferentialPrice(item);
                }
            });
        },

        // 附加型号选择标号(不是选择所有标号)
        additionalModelselectionLabel(item, k, event) {
            if (event) {
                const row = this.formData.ContractPriceMarkNumList.filter(z => z.compose_code === k.compose_code)[0];
                row.additionalModels.push({
                    additional_model: item.additional_model,
                    additional_model_code: item.additional_model_code,
                    id: item.cpaiid,
                });
            } else {
                const row = this.formData.ContractPriceMarkNumList.filter(z => z.compose_code === k.compose_code)[0];
                row.additionalModels.splice(
                    row.additionalModels.findIndex(z => z.additional_model_code === item.additional_model_code),
                    1
                );
            }
            if (item.checkedProductNumList.length === item.productNumList.length) {
                item.checkAll = true;
            } else {
                item.checkAll = false;
            }
            this.$forceUpdate();
        },

        // 市场信息价，企业固定价添加额外附加型号
        addAdditionalModels(additionalModels) {
            this.formData.ContractPriceMarkNumList.forEach(item => {
                item.additionalModels.push(additionalModels);
            });
        },

        // 市场信息价、企业固定价删除额外附加型号
        removeAdditionalModels(data, index, priceData) {
            priceData.additionalModels.splice(index, 1);
            this.formData.ContractPriceAdditionalList.forEach(item => {
                if (item.additional_model_code === data.additional_model_code) {
                    item.checkAll = false;
                    item.checkedProductNumList = [];
                    this.formData.ContractPriceMarkNumList.forEach(k => {
                        k.additionalModels.forEach(z => {
                            if (z.additional_model_code === data.additional_model_code) {
                                item.checkedProductNumList.push(k.compose_code);
                            }
                        });
                    });
                }
            });
            this.$forceUpdate();
        },

        // 选择泵送高度
        selectPumpingPrice() {
            this.$toast(
                {
                    title: true,
                    text: '选择泵送价格',
                    type: 'eject',
                    width: '15rem',
                    height: '90%',
                    t_url: 'tableList/index',
                    extr: {
                        code: { TableCode: 'choose_pump_height', QueryString: 'area_id=' + this.formData.area_id },
                        sureCallback: this.addPumpListCallback,
                    },
                }
            );
        },
        // 选取泵送价格（完成赋值）
        addPumpListCallback(data) {
            if (data) {
                data.map(item => {
                    const obj = {
                        cppiid: item.id,
                        // pump_height_code: item.smallclass_code,
                        pump_height_name: item.pump_height_name,
                        pump_upper_code: item.pump_upper_code,
                        pump_lower_code: item.pump_lower_code,
                        pump_upper: item.pump_upper,
                        pump_lower: item.pump_lower,
                        pump_price: item.pump_price,
                        sort_num: item.sort_num,
                    };
                    const arr = [];
                    this.formData.ContractPricePumpList.map(num => {
                        if (obj.pump_upper === num.pump_upper && obj.pump_lower === num.pump_lower) {
                            arr.push(num);
                        }
                    });
                    if (arr.length < 1) {
                        this.formData.ContractPricePumpList.push(obj);
                        this.formData.ContractPricePumpList.sort((a, b) => {
                            return a.sort_num - b.sort_num;
                        });
                    }
                });
            }
        },
        // 删除泵送价格
        delPumpPrice(data) {
            this.formData.ContractPricePumpList = this.formData.ContractPricePumpList.filter(item => {
                // 新增加时默认数据 cppiid 是空值 通过pump_height_name 筛选
                if (!data.cppiid) {
                    return item.pump_height_name !== data.pump_height_name;
                }
                return item.cppiid !== data.cppiid;

            });
        },
        // 获取泵送价格字典
        getPumpList() {
            this.$axios.get('/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=303-BS0&type=3')
                .then(res => {
                    if (res) {
                        this.pumpingHeightSelectData = res;
                    }
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 判断泵送价格是否可选
        judgePumpDisable(lower, k) {
            let b = false;
            if (parseInt(k.name, 0) <= parseInt(lower, 0)) {
                b = true;
            }
            return b;
        },

        // 保存
        save(successCallBack, errorCallBack) {
            const relationship = [];
            // 企业信息价低于最低限价数据集
            const lowFloorPrice = [];
            this.formData.ContractPriceMarkNumList.forEach(item => {
                item.additionalModels.forEach(k => {
                    relationship.push({
                        cpamiid: '',
                        cpaiid: k.id,
                        additional_model_code: k.additional_model_code,
                        additional_model: k.additional_model,
                        cpmiid: item.cpmiid,
                        product_mark_code: item.product_mark_code,
                        product_mark_num: item.product_mark_num,
                    });
                });
                const new_product_mark_price = Number(item.product_mark_price);
                if (item.product_mark_price_min && item.product_mark_price_min > new_product_mark_price) {
                    lowFloorPrice.push(item);
                }
            });
            this.formData.ContractPriceAdditionalMarkList = relationship;

            if (lowFloorPrice.length > 0) {
                const tipsHtml = [];
                const h = this.$createElement;
                lowFloorPrice.forEach(item => {
                    const priceDifference = item.product_mark_price_min - Number(item.product_mark_price);
                    const tipText = [
                        h('span', null, '标号'),
                        h('span', { style: 'color: red' }, `【${item.product_mark_num}】`),
                        h('span', null, '产品价格低于企业最低限价 '),
                        h('span', { style: 'color: red' }, priceDifference),
                        h('span', null, ' 元。'),
                    ];
                    tipsHtml.push(h('p', null, tipText));
                });
                this.$msgbox({
                    title: '提示',
                    message: h('div', null, [...tipsHtml, h('p', '是否提交?'),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.saveRequest(successCallBack, errorCallBack);
                }).catch(() => {
                    errorCallBack();
                    this.$message({
                        type: 'info',
                        message: '已取消保存',
                    });
                });
            } else {
                this.saveRequest(successCallBack, errorCallBack);
            }
        },

        // 保存请求
        saveRequest(successCallBack, errorCallBack) {
            this.$axios
                .post('/interfaceApi/sale/contract_price/info_price_instance', this.formData)
                .then(res => {
                    if (res) {
                        successCallBack(res);
                        this.formData.cpiid = res;
                    }
                }).catch(error => {
                    errorCallBack();
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 数值计算
        numericalCalculation(formula) {
            let calculationResults = 0;
            if (this.formData.price_value_type === 0) { // 取整
                if (this.formData.price_rounding_mode === 0) {
                    // 进一法
                    calculationResults = Math.ceil(formula);
                } else if (this.formData.price_rounding_mode === 1) {
                    // 四舍五入
                    calculationResults = Math.round(formula);
                } else if (this.formData.price_rounding_mode === 2) {
                    // 舍掉
                    calculationResults = Math.floor(formula);
                }
            } else if (this.formData.price_value_type === 1) { // 小数点后1位
                if (this.formData.price_rounding_mode === 0) {
                    // 进一法
                    calculationResults = NP.divide(Math.ceil(NP.divide(Math.floor(NP.times(formula, 100)), 10)), 10);
                } else if (this.formData.price_rounding_mode === 1) {
                    // 四舍五入
                    calculationResults = toFixedFun(formula, 1);
                } else if (this.formData.price_rounding_mode === 2) {
                    // 舍掉
                    calculationResults = Math.floor(NP.times(formula, 10)) / 10;
                }
            } else if (this.formData.price_value_type === 2) { // 小数点后2位
                if (this.formData.price_rounding_mode === 0) {
                    // 进一法
                    calculationResults = NP.divide(Math.ceil(NP.divide(Math.floor(NP.times(formula, 1000)), 10)), 100);
                } else if (this.formData.price_rounding_mode === 1) {
                    // 四舍五入
                    calculationResults = toFixedFun(formula, 2);
                } else if (this.formData.price_rounding_mode === 2) {
                    // 舍掉
                    calculationResults = NP.divide(Math.floor(NP.times(formula, 100)), 100);
                }
            }
            return calculationResults;
        },
        // 查看更多附加型号
        viewMoreTag(data) {
            this.dialogTitle = data.product_mark_num + '-附加型号';
            this.dialogVisible = true;
            this.currentNumMore = data;
        },
        // 信息价取整选择
        switchCalculationMode() {
            // 格式化市场信息价数据
            this.selectMarketInformationPriceCallback();
            // 格式化企业固定价数据
            this.enterpriseFixedPriceValue();
            // 格式化附加型号数据
            this.additionalModelValue();
        },

        // 处理市场信息价数据
        processingInfoPriceData() {
            // 市场信息价
            this.marketValueData = [];
            // 企业信息价
            this.enterprisePriceData = [];
            if (!this.formData.ContractPriceMarkNumList) {return;}
            this.formData.ContractPriceMarkNumList.forEach(item => {
                if (item.price_type === 0) {
                    // 企业信息价
                    this.enterprisePriceData.push(item);
                } else {
                    // 市场信息价
                    this.marketValueData.push(item);
                }
            });
        },
    },
};
</script>

<style lang="stylus">
    @import 'css/priceSettingForm.stylus';
    .cell-center
        text-align center
</style>
